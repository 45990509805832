import * as React from "react"
import SelfAssesment from "../templates/SelfAssesment"
import Layout from "../components/Layout"

const SelfAssessmentPage = () => {
  return (
   <>
    <Layout>
      <SelfAssesment />
    </Layout>
   </>
  )
}

export default SelfAssessmentPage